import React from 'react';
/* eslint-disable */
export function EyeShield(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="15" cy="13" r="1.25" stroke="#525266" />
      <path
        d="M19.4101 11.9547C19.8088 12.3943 20.0081 12.6141 20.0081 13C20.0081 13.3859 19.8088 13.6057 19.4101 14.0453C18.4748 15.0767 16.849 16.5 15 16.5C13.151 16.5 11.5252 15.0767 10.5899 14.0453C10.1912 13.6057 9.99188 13.3859 9.99188 13C9.99188 12.6141 10.1912 12.3943 10.5899 11.9547C11.5252 10.9233 13.151 9.5 15 9.5C16.849 9.5 18.4748 10.9233 19.4101 11.9547Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M23.462 6.28862L23.0681 7.20776L23.462 6.28862L15.7878 2.99968C15.2847 2.78406 14.7153 2.78406 14.2122 2.99968L6.53797 6.28862C5.72805 6.63572 5.24073 7.472 5.33804 8.34777L6.23939 16.4599C6.43906 18.2569 7.3263 19.907 8.71529 21.0644L13.7196 25.2347C14.4613 25.8528 15.5387 25.8528 16.2804 25.2347L21.2847 21.0644C22.6737 19.907 23.5609 18.2569 23.7606 16.4599L24.662 8.34777C24.7593 7.47199 24.2719 6.63572 23.462 6.28862Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
