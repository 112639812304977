import React from 'react';
/* eslint-disable */
export function MusicNote(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.25 20H7.81556C7.0565 20 6.67696 20 6.37035 20.1017C5.77087 20.3005 5.30054 20.7709 5.1017 21.3704C5 21.677 5 22.0565 5 22.8156V22.8156C5 23.7218 5 24.1749 5.12857 24.5159C5.38062 25.1845 5.97018 25.6678 6.6752 25.7838C7.03484 25.843 7.47914 25.7542 8.36774 25.5765L8.73786 25.5024C9.59647 25.3307 10.0258 25.2448 10.3522 25.0293C10.689 24.8069 10.9506 24.4877 11.1026 24.1139C11.25 23.7515 11.25 23.3137 11.25 22.4381V10.7792C11.25 9.20946 11.25 8.42459 11.7033 7.87163C12.1566 7.31867 12.9263 7.16475 14.4655 6.85689L18.9655 5.95689C21.1792 5.51417 22.286 5.29281 23.018 5.89292C23.75 6.49304 23.75 7.62176 23.75 9.87922V11.25M23.75 17.5V19.9381C23.75 20.8137 23.75 21.2515 23.6026 21.6139C23.4506 21.9877 23.189 22.3069 22.8522 22.5293C22.5258 22.7448 22.0965 22.8307 21.2379 23.0024L20.8677 23.0765C19.9791 23.2542 19.5348 23.343 19.1752 23.2838C18.4702 23.1678 17.8806 22.6845 17.6286 22.0159C17.5 21.6749 17.5 21.2218 17.5 20.3156V20.3156C17.5 19.5565 17.5 19.177 17.6017 18.8704C17.8005 18.2709 18.2709 17.8005 18.8704 17.6017C19.177 17.5 19.5565 17.5 20.3156 17.5H23.75ZM23.75 17.5V11.25M23.75 11.25L11.25 13.75"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}
